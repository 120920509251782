@import '../../styles/variables';

.form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  font-family: $fontFamily;

  & .input {
    border: 0;
    color: $white;
    font-size: 30px;
    background: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    margin-bottom: 40px;
    padding: 15px 0;
    font-family: $fontFamily;
  }
  & .input::-webkit-contacts-auto-fill-button {
    display: none !important;
    position: absolute;
    right: 0;
    visibility: hidden;
  }

  & .input:focus {
    border-bottom: 1px solid $color-accent;
    caret-color: $color-accent;
    outline: none;
  }

  & .submitBtn {
    background-color: $color-accent;
    color: $white;
    font-size: 20px;
    border-radius: 30px;
    width: 100%;
    padding: 20px 0;
    border: 0;
    margin-top: 20px;
    transition: ease 300ms;

    &:hover {
      cursor: pointer;
      background-color: lighten($color-accent, 3%);
    }
  }

  & .submitBtn[disabled] {
    opacity: 0.2;
    background-color: $white;
    color: $black;
  }
}

.WorkWithUsContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 1440px;

  & .logo {
    width: 80px;
    height: 80px;
  }
  & .heading {
    font-size: 80px;
    line-height: 96px;
    text-align: center;
    margin: 40px 0;
    background-color: $color-accent;
    background-image: $accent-gradient;
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  & .thanksMessage {
    text-align: center;
    font-size: 40px;
    max-width: 55%;
    color: $color-dark-blue;
  }

  & .closeBtn {
    border-radius: 40px;
    padding: 20px 60px;
    background: none;
    color: $color-dark-blue;
    border: 2px solid $color-dark-blue;
    margin-top: 40px;
    font-size: 20px;
    transition: ease 600ms;

    &:hover {
      background-color: $color-dark-blue;
      color: $white;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .WorkWithUsContent {
    & .heading {
      font-size: 68px;
      line-height: 72px;
    }
    & .thanksMessage {
      font-size: 32px;
    }
  }
}

@media only screen and (max-width: $mobile-lg-breakpoint) {
  .WorkWithUsContent {
    max-width: 400px;
    padding: 0 25px;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .WorkWithUsContent {
    max-width: 300px;
    & .heading {
      font-size: 56px;
      line-height: 72px;
    }
    & .thanksMessage {
      font-size: 24px;
      max-width: 75%;
    }
  }
}

@import '../../styles/variables';

.container {
  padding: 0 80px;
  margin: 0 auto;

  &.maxWidth {
    max-width: 1280px;
  }
  &.maxWidthJobs {
    max-width: 1340px;
  }
}
.noPadding {
  padding: 0;
}

@media only screen and (max-width: $laptop-breakpoint) {
  .container {
    padding: 0 50px;
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .container {
    padding: 0 20px;
  }
}

@media only screen and (max-width: $mobile-xs-breakpoint) {
  .container {
    padding: 0 10px;
  }
}

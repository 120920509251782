@import 'variables';

.blog-page {
  background-color: $color-light-blue;

  .background-light-container {
    background-color: #ebeefc;
  }

  .blog-hero-section {
    background-image: linear-gradient(121deg, $color-blue 0%, #a828ce 100%);
  }

  .blog-hero-content {
    margin-top: 30px;

    .hero-blog-preview {
      position: relative;
      z-index: 2;
    }
  }

  .blog-posts-grid {
    padding-top: 170px;
    position: relative;
    margin-top: -100px;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -40px;

    .blog-post-card {
      margin-right: 40px;
    }

    .blog-post-card {
      width: calc(33.33% - 40px);
      border-radius: 30px;
      background-color: $white;
      margin-bottom: 60px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      transition: box-shadow, transform;
      transition-duration: 300ms;

      &:hover {
        cursor: pointer;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
        transform: translateY(-5px);

        & .post-footer {
          .post-arrow-right {
            color: lighten($color-accent, 5%);
          }
        }
      }

      .post-cover-section {
        position: relative;

        .img {
          height: 240px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .post-topic {
          position: absolute;
          top: 30px;
          left: 30px;
          font-size: 18px;
          font-weight: 500;
          line-height: 40px;
          padding: 0 15px;
          background-color: $color-accent;
          color: $white;
          border-radius: 20px;

          &::first-letter {
            text-transform: capitalize;
          }
        }
      }
      text-decoration: none;

      .post-content {
        padding: 30px 35px 35px 35px;
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      .post-heading {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 15px;
        font-weight: 500;
        color: $color-dark-blue;
      }

      .post-description {
        font-size: 18px;
        line-height: 24px;
        opacity: 0.8;
        color: $color-dark-blue;
      }

      .post-footer {
        flex: 1;
        display: flex;
        align-items: flex-end;
        margin-top: 30px;

        .post-arrow-right {
          color: $color-accent;
          position: relative;
        }

        .post-footer-items {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        .post-footer-author {
          display: flex;
          align-items: center;
        }

        .post-author-pic {
          width: 40px;
          height: 40px;
          object-fit: cover;
          margin-right: 15px;
          border-radius: 50% 50%;
        }

        .post-author {
          font-size: 16px;
          opacity: 0.8;
          color: $color-dark-blue;
        }
      }
    }

    .blog-post-card.big-card {
      width: 100%;
      border-radius: 40px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 50px 80px;
      background-color: $color-dark-blue;
      color: $white;

      .post-content {
        flex: 1;
        padding: 0;
        max-width: 55%;

        .post-heading {
          font-size: 40px;
          line-height: 52px;
          color: $white;
        }

        .post-description {
          font-size: 24px;
          line-height: 32px;
          color: $white;
        }

        .post-author {
          font-size: 20px;
          color: $white;
        }

        .post-topic {
          font-size: 24px;
          margin-bottom: 13px;
          color: $color-accent;

          &::first-letter {
            text-transform: capitalize;
          }
        }
      }

      .post-action-section {
        display: flex;
        align-items: center;
        text-decoration: none;

        &:hover {
          cursor: pointer;
        }
      }

      .post-action-text {
        font-size: 20px;
        color: $color-accent;
        margin-right: 10px;
      }

      .post-footer {
        .post-arrow-right {
          padding: 0;
          right: 0;
        }
      }

      .post-cover-section {
        max-width: 360px;
        max-height: 360px;
        aspect-ratio: 1/1;

        .post-topic {
          display: none;
        }
      }

      .post-cover-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50% 50%;
      }
    }
  }

  .hero-blog-preview {
    border-radius: 40px;
    background-color: $white;
    color: $color-dark-blue;
    text-decoration: none;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 50px 80px;
    transition: box-shadow, transform;
    transition-duration: 300ms;

    .blog-post-preview-content {
      display: flex;
      flex-direction: column;
      max-width: 55%;
      flex: 1;

      .title {
        font-size: 40px;
        font-weight: 500;
        line-height: 50px;
        margin-bottom: 15px;
      }

      .description {
        font-size: 24px;
        line-height: 32px;
      }

      .type {
        font-size: 24px;
        font-weight: 500;
        color: $color-accent;
        margin-bottom: 12px;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      .blog-footer {
        display: flex;
        justify-content: space-between;
        flex: 1;
        align-items: flex-end;
        margin-top: 30px;

        .author {
          font-size: 20px;
          opacity: 0.8;
        }

        .blog-post-link {
          .action-btn {
            color: $color-accent;
            text-decoration: none;
            font-size: 20px;
            font-weight: 500;
            display: flex;
            align-items: center;

            .arrow-right {
              margin-left: 10px;
            }
          }
        }
      }
    }

    &:hover {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
      transform: translateY(-5px);

      .blog-footer {
        .blog-post-link {
          .action-btn {
            color: darken($color-accent, 5%);
          }
        }
      }
    }

    .img {
      max-width: 360px;
      max-height: 360px;

      img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
}

@media only screen and (max-width: $laptop-breakpoint) {
  .blog-page {
    .hero-blog-preview {
      .blog-post-content {
        max-width: 55%;
      }

      .img {
        margin-right: 30px;
      }
    }

    .blog-posts-grid {
      .blog-post-card.big-card {
        .post-content {
          max-width: 55%;
        }

        .post-cover-section {
          margin-left: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: $small-laptop-breakpoint) {
  .blog-page {
    .hero-blog-preview {
      padding-left: 35px;
      padding-right: 35px;
      align-items: center;
      flex-direction: column;

      .blog-post-preview-content {
        max-width: 100%;
      }
      .img {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }

    .blog-posts-grid {
      .blog-post-card {
        width: 100%;

        .post-cover-section {
          .img {
            height: unset;
          }
        }
      }

      .blog-post-card.big-card {
        flex-direction: column;
        align-items: center;
        padding-left: 35px;
        padding-right: 35px;
        .post-content {
          max-width: 100%;
        }
        .post-cover-section {
          margin-left: 0;
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .blog-page {
    .hero-blog-preview {
      padding: 40px;
    }

    .blog-posts-grid {
      .blog-post-card.big-card {
        padding: 40px;

        .post-footer {
          .post-arrow-right {
            right: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-lg-breakpoint) {
  .blog-page {
    .hero-blog-preview {
      padding: 40px 20px;

      .blog-post-preview-content {
        .type {
          font-size: 18px;
        }

        .title {
          font-size: 30px;
          line-height: 40px;
        }

        .description {
          font-size: 18px;
          line-height: 24px;
        }

        .blog-footer {
          align-items: flex-start;
          flex-direction: column;

          .author {
            font-size: 16px;
          }

          .blog-post-link {
            margin-top: 30px;

            .action-btn {
              font-size: 16px;
            }
          }
        }
      }
    }

    .blog-posts-grid {
      .blog-post-card {
        .post-content {
          padding: 30px 20px;
        }
      }

      .blog-post-card.big-card {
        padding: 40px 20px;

        .post-cover-section {
          width: 260px;
          height: 260px;
        }

        .post-content {
          .post-topic {
            font-size: 18px;
          }

          .post-heading {
            font-size: 30px;
            line-height: 40px;
          }

          .post-description {
            font-size: 18px;
            line-height: 24px;
          }

          .post-footer {
            .post-footer-items {
              align-items: flex-start;
              flex-direction: column;

              .post-footer-author {
                .post-author {
                  font-size: 16px;
                }
              }

              .post-action-section {
                margin-top: 30px;

                .post-action-text {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@import 'src/styles/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 40px 0;

  & .logoLink {
    display: flex;
    align-items: center;
    & svg {
      width: 202px;
      height: 30px;
    }
  }
}

.linksContainer {
  display: flex;
  align-items: center;

  & .link:not(.link.btn) {
    margin-right: 40px;
  }

  & .link {
    transition: color ease 400ms;
    font-size: 20px;
    line-height: 40px;
    color: $white;
    text-decoration: none;

    &:hover {
      color: lighten($color-accent, 5%);
    }
  }

  & .link.btn {
    padding: 10px 30px;
    border: 2px solid $white;
    border-radius: 30px;
    background: transparent;

    &:hover {
      cursor: pointer;
      border-color: lighten($color-accent, 5%);
      color: lighten($color-accent, 5%);
    }
  }
}
.linksJobsContainer {
  display: flex;
  align-items: center;

  & .link:not(.link.btn) {
    margin-right: 40px;
  }

  & .link {
    transition: color ease 400ms;
    font-size: 20px;
    line-height: 40px;
    color: $white;
    text-decoration: none;

    &:hover {
      color: lighten($color-accent, 5%);
    }
  }

  & .link.btn {
    padding: 10px 30px;
    border: 2px solid $white;
    border-radius: 30px;
    background: transparent;

    &:hover {
      cursor: pointer;
      border-color: lighten($color-accent, 5%);
      color: lighten($color-accent, 5%);
    }
  }
}

.mobileMenuButton {
  display: none;
  outline: none;
  background: transparent;
  border: none;
  margin-left: 20px;
  color: $white;

  &:hover {
    color: $color-accent;
  }
}
.mobileJobsMenuButton {
  display: none;
  outline: none;
  background: transparent;
  border: none;
  margin-left: 20px;
  color: $white;
  cursor: pointer;

  &:hover {
    color: $color-accent;
  }
}
@media only screen and (max-width: $laptop-breakpoint) {
  .linksJobsContainer {
    & .link {
      font-size: 18px;
    }
    & .link.btn {
      padding: 6px 20px;
    }
  }
}

@media only screen and (max-width: $small-laptop-breakpoint) {
  .mobileJobsMenuButton {
    display: block;
    margin-left: 10px;
    & svg {
      width: 45px;
      height: 45px;
    }
  }

  .header {
    & .linksJobsContainer {
      & .link:not(.btn) {
        display: none;
      }
      & .link.btn {
        padding: 2px 16px;
      }
      .logoLink {
        & svg {
          width: 300px;
          height: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .logoLink {
    & svg {
      width: 300px;
      height: 30px;
    }
  }
  .mobileMenuButton {
    display: block;
    & svg {
      width: 45px;
      height: 45px;
    }
  }
  .mobileJobsMenuButton {
    display: block;
    & svg {
      width: 45px;
      height: 45px;
    }
  }

  .header {
    & .linksContainer {
      & .link:not(.btn) {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: $mobile-xl-breakpoint) {
  .mobileJobsMenuButton {
    position: relative;
    right: -8px;
  }
  .header {
    padding: 20px 0;

    & .logoLink svg {
      width: 115px;
      height: 18.31px;
    }

    & .linksContainer {
      & .link.btn {
        padding: 8px 16px;
        font-size: 14px;
        line-height: 18.23px;
      }
    }
    & .linksJobsContainer {
      & .link.btn {
        padding: 8px 16px;
        font-size: 14px;
        line-height: 18.23px;
      }
    }
  }

  .mobileMenuButton {
    margin-left: 20px;
  }
  .mobileJobsMenuButton {
    margin-left: 8px;
    padding: 0;
  }
}

@media only screen and (max-width: $mobile-lg-breakpoint) {
  .mobileMenuButton {
    margin-left: 10px;
    padding: 0;
    & svg {
      width: 40px;
      height: 40px;
    }
  }
  .mobileJobsMenuButton {
    margin-left: 0px;
    padding: 0;
    & svg {
      width: 40px;
      height: 40px;
    }
  }
  .header {
    padding: 10px 0;

    & .logoLink svg {
      width: 115px;
    }
  }
}

@media only screen and (max-width: $mobile-xs-breakpoint) {
  .header {
    & .mobileMenuButton {
      & svg {
        width: 32px;
        height: 32px;
      }
    }
    & .mobileJobsMenuButton {
      right: -4px;
      & svg {
        width: 32px;
        height: 32px;
      }
    }
    & .logoLink svg {
      width: 100px;
    }
    & .link.btn {
      font-size: 12px;
      line-height: 18.23px;
    }
  }
}

@import 'variables';

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''), url('../assets/fonts/dm-sans-regular.woff2') format('woff2'),
    url('../assets/fonts/dm-sans-regular.woff') format('woff');
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''), url('../assets/fonts/dm-sans-500.woff2') format('woff2'),
    url('../assets/fonts/dm-sans-500.woff') format('woff');
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif;
  background-color: $color-dark-blue;
}

h1,
h2,
h3,
h4,
h5,
span,
p {
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: 'DM Sans', sans-serif;
}

button {
  font-family: 'DM Sans', sans-serif;
}

.header-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.blog-previews-container {
  background-image: linear-gradient(123deg, #002295 0%, #a828ce 100%);
  padding-bottom: 110px;
  padding-top: 200px;
  position: relative;

  & .content {
    & .heading {
      font-size: 80px;
      line-height: 90px;
      margin-bottom: 25px;
      text-align: center;
      color: $white;
    }
  }
}

@media only screen and (max-width: $laptop-breakpoint) {
  .blog-previews-container {
    & .content {
      & .heading {
        font-size: 68px;
        line-height: 80px;
      }
    }
  }
}

@media only screen and (max-width: $small-laptop-breakpoint) {
  .hero-overlay,
  .gareth-video {
    max-height: 700px;
  }
}
@media only screen and (max-width: $tablet-breakpoint) {
  .blog-previews-container {
    & .content {
      & .heading {
        font-size: 56px;
        line-height: 64px;
      }
    }
  }
}

@media only screen and (max-width: $mobile-xl-breakpoint) {
  .header-container {
    position: relative;
    background-color: $color-dark-blue;
  }
  .hero-overlay,
  .gareth-video {
    max-height: 580px;
  }
  .blog-previews-container {
    & .content {
      & .heading {
        font-size: 43px;
        line-height: 56px;
      }
    }
  }
}

.hero-overlay {
  position: relative;
  height: 100vh;
}

.gareth-video {
  height: 100vh;
  position: relative;
}

@media only screen and (max-width: $mobile-lg-breakpoint) {
  .hero-overlay,
  .gareth-video {
    height: 80vh;
  }
  .blog-previews-container {
    & .content {
      & .heading {
        font-size: 40px;
        line-height: 50px;
      }
    }
  }
}

@import 'src/styles/variables';

.mobileMenuEnter {
  opacity: 0;
  transform: translateX(100%);
}
.mobileMenuEnterActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.mobileMenuExit {
  opacity: 1;
  transform: translateX(0);
}
.mobileMenuExitActive {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms, transform 300ms;
}

@media only screen and (max-width: $small-laptop-breakpoint) {
  .logoLinkContainer {
    & svg {
      width: 300px;
      height: 30px;
    }
  }
  .mobileMenuJobsContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: $color-dark-blue;
    opacity: 1;
    padding-bottom: 20px;

    & .logoLinkContainer {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .content {
      width: 100%;
      height: 95%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .navigationLinks {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & .link {
          font-size: 48px;
          line-height: 71px;
          text-decoration: none;
          color: $white;
          margin-bottom: 10px;
        }

        & .link.active {
          opacity: 0.2;
        }
      }
    }
    & .closeCircle {
      position: absolute;
      top: 25px;
      right: 25px;
    }
    .closeIcon {
      width: 40px;
      height: 40px;
    }
  }
}
@media only screen and (max-width: $tablet-breakpoint) {
  .mobileMenuContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: $color-dark-blue;
    opacity: 1;
    padding-bottom: 20px;

    & .logoLinkContainer {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .content {
      width: 100%;
      height: 95%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .navigationLinks {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & .link {
          font-size: 48px;
          line-height: 71px;
          text-decoration: none;
          color: $white;
          margin-bottom: 10px;
        }

        & .link.active {
          opacity: 0.2;
        }
      }
    }

    & .closeCircle {
      position: absolute;
      top: 25px;
      right: 25px;
    }
    .closeIcon {
      width: 40px;
      height: 40px;
    }
  }
  //   .mobileMenuButton {
  //     display: block;
  //     & svg {
  //       width: 45px;
  //       height: 45px;
  //     }
  //   }
}
@media only screen and (max-width: $mobile-xl-breakpoint) {
}

@media only screen and (max-width: $mobile-lg-breakpoint) {
}

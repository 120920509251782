@import '../../styles/variables';

.footer {
  color: $white;
  padding-top: 80px;
  padding-bottom: 80px;

  & h4 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  & p,
  & li {
    font-size: 18px;
    line-height: 22px;
    opacity: 0.6;
  }

  & li:hover {
    opacity: 1;
  }

  & li > a {
    color: $white;
    text-decoration: none;
  }

  & ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  & .footerContent {
    display: flex;
    justify-content: space-between;
    padding-top: 80px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    z-index: 2;
    position: relative;
  }

  & .addingValueContainer {
    flex: 2;

    & p {
      max-width: 700px;
    }
  }

  & .copyrightContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 18px;
    line-height: 22px;

    & span {
      opacity: 0.6;
    }
  }

  & .techeryLogo {
    width: 40px;
    height: 40px;
  }

  & .rightContent {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
}

.footer.light,
.footer.transparent {
  color: $color-dark-blue;

  & svg.techeryLogo {
    width: 40px;
    height: 40px;
  }

  & .footerContent {
    border-top: 1px solid rgba(8, 13, 28, 0.2);
  }

  & li > a {
    color: $color-dark-blue;
  }
}

.footer.light {
  background-color: $color-light-blue;
}

.footer.transparent {
  background-color: transparent;
}

@media only screen and (max-width: $laptop-breakpoint) {
  .footer {
    & .footerContent {
      flex-direction: column;
    }

    & .addingValueContainer {
      margin-bottom: 50px;
    }
  }
}

@media only screen and (max-width: $mobile-xs-breakpoint) {
  .footer {
    & .rightContent {
      flex-direction: column;
      align-items: flex-start;
      & .copyrightContainer {
        margin-top: 40px;
        align-items: flex-start;
      }
    }
  }
}

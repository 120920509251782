@import '../../styles/variables';

.modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 4;
  background-color: $color-dark-blue;
  opacity: 0;
  transform: scale(0.99);
  transition: ease 600ms;

  & .closeCircle {
    position: absolute;
    top: 25px;
    right: 25px;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    & svg {
      width: 60px;
      height: 60px;
    }
  }

  &.in.visible {
    opacity: 1;
    transform: scale(1);
  }

  &.skyBg {
    background-color: $color-sky-blue;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    max-width: 1440px;
    margin: 0 auto;
    font-family: $fontFamily;
  }
}

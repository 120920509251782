$color-blue: #002295;
$color-dark-blue: #080d1c;
$color-accent: #f0467a;
$color-grey: #d8d8d8;
$color-sky-blue: #eceffd;
$color-light-blue: #ebeefc;
$white: #fff;
$black: #000;
$accent-gradient: linear-gradient(135deg, #4c2d8c, #f0457a);
$accent-gradient-hover: linear-gradient(
  135deg,
  lighten(#4c2d8c, 10%),
  lighten(#f0457a, 10%)
);

$fontFamily: 'DM Sans';

$laptop-breakpoint: 1366px;
$small-laptop-breakpoint: 1024px;
$tablet-breakpoint: 768px;
$mobile-xl-breakpoint: 540px;
$mobile-lg-breakpoint: 425px;
$mobile-breakpoint: 375px;
$mobile-xs-breakpoint: 320px;
